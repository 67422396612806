import { Layout } from '../components/_layout';

export const Home: React.FC = () => {
    return (
        <Layout title="Benvenuti">
            <h1>Benvenuti</h1>
            <div className="mt-4">
                In quest&apos;area amministrativa è possibile gestire tutti gli aspetti che riguardano la gestione delle pubblicazioni, degli abbonamenti e
                degli utenti che possono scaricare le pubblicazioni di Bioetica.
            </div>
        </Layout>
    );
};
