import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { Book } from '@biosub/common/Book';
import { PagedResult } from '@biosub/common/PagedResult';
import { Article } from '@biosub/common/Article';

import { Layout } from '../../components/_layout';
import Select from '../../components/Select';

export const ArticleList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('title_ASC');

    const { bookId } = useParams<{ bookId: string }>();

    const [book] = useQuery<{ bookADView: Book }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                bookADView(id: $id) {
                    title
                }
            }
        `,
        variables: { id: bookId }
    });

    const [articles] = useQuery<{ articleADList: PagedResult<Article> }>({
        query: /* GraphQL */ `
            query ($bookId: ID!, $limit: Int!, $cursor: Int, $orderBy: ArticleOrderByInput) {
                articleADList(bookId: $bookId, limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        title
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { bookId, limit, cursor, orderBy }
    });

    if (book.error || articles.error) {
        notification.error(book.error || articles.error || 'Si è verificato un errore inatteso');
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteArticle] = useMutation(/* GraphQL */ `
        mutation ($id: ID!) {
            deleteArticle(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo articolo?')) {
            const result = await deleteArticle({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Articolo eliminato con successo');
            }
        }
    };

    return (
        <Layout title="Elenco articoli">
            <>
                <div className="flex">
                    <div className="flex-1 flex items-center">
                        <Link to={`/articles/manage/${bookId}`} className="mr-4 text-green-500">
                            <i className="las la-plus-circle la-3x" />
                        </Link>
                        <h3>Articoli per la pubblicazione: {book.data?.bookADView.title}</h3>
                    </div>

                    <div className="w-64 ml-auto">
                        <div className="text-right text-sm mb-1">Ordina articoli per</div>
                        <Select onChange={val => fetch(cursor, val.target.value)}>
                            <option value="title_ASC">Titolo A-Z</option>
                            <option value="title_DESC">Titolo Z-A</option>
                        </Select>
                    </div>
                </div>

                {book.fetching ||
                    (articles.fetching && (
                        <div className="p-4 flex justify-center">
                            <span className="text-xl">Caricamento...</span>
                        </div>
                    ))}

                {book.error ||
                    (articles.error && (
                        <div className="p-4 flex justify-center">
                            <span className="text-xl text-bioetica-red">Si è verificato un errore nel gestire la richiesta</span>
                        </div>
                    ))}

                {articles.data && (
                    <>
                        <table className="mt-4 table-auto w-full">
                            <thead>
                                <tr>
                                    <th className="w-12 text-xl font-semibold">#</th>
                                    <th className="px-4 py-2 text-xl font-semibold text-left">Titolo</th>
                                    <th className="w-3/12 px-4 py-2 text-xl font-semibold">Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {articles.data?.articleADList.edges.map((a: Article, i: number) => (
                                    <tr key={a.id} className="odd:bg-gray-100">
                                        <td className="p-2 text-center">{cursor + i + 1}</td>
                                        <td className="p-4">{a.title}</td>
                                        <td className="p-4 border-l border-gray-200 flex">
                                            <Link to={`/articles/manage/${bookId}/${a.id}`} className="flex-1 button small green">
                                                Modifica
                                            </Link>
                                            &nbsp;
                                            <button className="flex-1 button small red" onClick={() => doDelete(a.id as string)}>
                                                Elimina
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="mt-8 flex justify-center">
                            <div className="border border-gray-700 rounded flex">
                                {cursor > 0 && (
                                    <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                        INDIETRO
                                    </button>
                                )}

                                {articles.data.articleADList.pageInfo.hasNextPage && (
                                    <button
                                        className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                        onClick={() => fetch(articles.data?.articleADList.pageInfo.endCursor || 0)}
                                    >
                                        AVANTI
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </>
        </Layout>
    );
};
