import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { RequireAuth } from './RequireAuth';

import { Home } from './pages/home';
import { Login } from './pages/login';
import { UserList } from './pages/users';
import { ManageUser } from './pages/users/manage';
import { BookList } from './pages/books';
import { ManageBook } from './pages/books/manage';
import { ArticleList } from './pages/articles';
import { ManageArticle } from './pages/articles/manage';
import { SubscriptionList } from './pages/subscriptions';
import { ManageSubscription } from './pages/subscriptions/manage';

import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/users" element={<UserList />} />
                        <Route path="/users/manage/:id" element={<ManageUser />} />
                        <Route path="/books" element={<BookList />} />
                        <Route path="/books/manage/:id" element={<ManageBook />} />
                        <Route path="/articles/:bookId" element={<ArticleList />} />
                        <Route path="/articles/manage/:bookId/:id" element={<ManageArticle />} />
                        <Route path="/subscriptions" element={<SubscriptionList />} />
                        <Route path="/subscriptions/manage/:id" element={<ManageSubscription />} />
                    </Route>
                </Routes>
            </Router>

            <ToastContainer
                key="ToastContainer"
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                closeButton={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                pauseOnHover={false}
                theme="colored"
            />
        </>
    );
}
