import { ReactNode, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

import logo from '../assets/LOGO_BIO_2.png';

type LayoutProps = {
    title?: string;
    children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `${props.title} - Consulta di Bioetica`;
    });

    const doLogout = async (): Promise<void> => {
        if (logout) {
            logout();
            navigate('/login');
        }
    };

    return (
        <div className="w-2/3 mx-auto">
            <nav className="border-b-2 border-gray-700">
                <ul className="flex h-full">
                    <li>
                        <Link to="/" className="w-full h-full p-2 pr-8 flex items-center justify-center">
                            <img src={logo} className="h-16" alt="Consulta di Bioetica" title="Consulta di Bioetica" />
                        </Link>
                    </li>
                    <li className="flex-1">
                        <Link to="/users" className="p-4 flex flex-col items-center justify-center hover:bg-gray-200">
                            <i className="las la-users la-2x mr-1" />
                            Utenti
                        </Link>
                    </li>
                    <li className="flex-1">
                        <Link to="/subscriptions" className="p-4 flex flex-col items-center justify-center hover:bg-gray-200">
                            <i className="las la-coins la-2x mr-1" />
                            Abbonamenti
                        </Link>
                    </li>
                    <li className="flex-1">
                        <Link to="/books" className="p-4 flex flex-col items-center justify-center hover:bg-gray-200">
                            <i className="las la-book la-2x mr-1" />
                            Pubblicazioni
                        </Link>
                    </li>
                    <li>
                        <button className="p-4 pl-8 flex flex-col items-center justify-center hover:bg-gray-200" onClick={() => doLogout()}>
                            <i className="las la-sign-out-alt la-2x mr-1" />
                            Logout
                        </button>
                    </li>
                </ul>
            </nav>

            <section className="mt-4">{props.children}</section>

            <footer className="border-t-2 border-bioetica-red mt-8">
                <div className="py-2"><>© {new Date().getFullYear()} Consulta di Bioetica</></div>
            </footer>
        </div>
    );
};
