import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { Book } from '@biosub/common/Book';
import { Article, ArticleInput } from '@biosub/common/Article';
import { GQLFile } from '@biosub/common/GQLFile';

import { Layout } from '../../components/_layout';
import WYSIWYGEditor from '../../components/Wysiwyg';

export const ManageArticle: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<ArticleInput>();

    const { bookId, id } = useParams();

    const [pdfs, setPDFs] = useState<GQLFile[]>();

    const [book] = useQuery<{ bookADView: Book }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                bookADView(id: $id) {
                    title
                    description
                    cover {
                        url
                    }
                }
            }
        `,
        variables: { id: bookId }
    });

    const [article] = useQuery<{ articleADView: Article }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                articleADView(id: $id) {
                    title
                    abstract
                    authors
                    keywords
                    pdfs {
                        id
                        filename
                        url
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    if (book.error || article.error) {
        notification.error(book.error || article.error || 'Si è verificato un errore inatteso');
    }

    useEffect(() => {
        if (!article.fetching && article.data && article.data.articleADView) {
            const data = article.data.articleADView as Article;
            setValue('title', data.title, { shouldValidate: true, shouldDirty: true });
            setValue('abstract', data.abstract, { shouldValidate: true, shouldDirty: true });
            setValue('authors', data.authors, { shouldValidate: true, shouldDirty: true });
            setValue('keywords', data.keywords, { shouldValidate: true, shouldDirty: true });

            if (data.pdfs) {
                setPDFs(data.pdfs);
            }
        }
    }, [article, setValue]);

    const [, manageArticle] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: ArticleInput!) {
            manageArticle(id: $id, input: $input) {
                id
            }
        }
    `);

    const onSubmit = async (input: ArticleInput) => {
        input.book = bookId as string;
        const result = await manageArticle({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/articles/manage/${bookId}/${result.data.manageArticle.id}`, { replace: true });
            }

            notification.success('Articolo salvato con successo!');
        }
    };

    const [, addPDF] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $file: Upload!) {
            addPDF(id: $id, file: $file) {
                id
                filename
                url
            }
        }
    `);

    const PDFADD = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const result = await addPDF({ id, file });

            if (result.error) {
                notification.error(result.error);
            } else {
                if (!pdfs) {
                    setPDFs([result.data.addPDF]);
                } else {
                    setPDFs([result.data.addPDF, ...pdfs]);
                }

                notification.success('PDF aggiunto con successo!');
            }
        }
    };

    const [, removePDF] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $PDFId: ID!) {
            removePDF(id: $id, PDFId: $PDFId)
        }
    `);

    const PDFRM = async (PDFId: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo PDF?')) {
            const result = await removePDF({ id, PDFId });

            if (result.error) {
                notification.error(result.error);
            } else {
                const temp = [...(pdfs || [])];
                const index = temp.findIndex(p => p.id === PDFId);
                if (index > -1) {
                    temp?.splice(index, 1);
                    setPDFs(temp);
                }

                notification.success('PDF rimosso con successo!');
            }
        }
    };

    return (
        <Layout title="Gestisci articolo">
            <h5>Pubblicazione: {book.data?.bookADView.title}</h5>
            <h2 className="mt-4">Gestisci articolo</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="title">
                        Titolo *
                    </label>
                    <div className="flex-1">
                        <input {...register('title', { required: true })} placeholder="Titolo" className="input" />
                        {errors.title && <div className="input-error">Il titolo è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end mt-1" htmlFor="abstract">
                        Abstract *
                    </label>
                    <div className="flex-1 bg-gray-200 rounded">
                        <Controller
                            render={({ field }) => <WYSIWYGEditor value={field.value} onChange={field.onChange} />}
                            name="abstract"
                            control={control}
                            rules={{ required: true }}
                        />
                        {errors.abstract && <div className="input-error">La descrizione breve è obbligatoria</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="authors">
                        Autori *
                    </label>
                    <div className="flex-1">
                        <input {...register('authors', { required: true })} placeholder="Autori (separati da virgola)" className="input" />
                        {errors.authors && <div className="input-error">Gli autori sono obbligatori</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="keywords">
                        Parole chiave *
                    </label>
                    <div className="flex-1">
                        <input {...register('keywords', { required: true })} placeholder="Parole chiave (separate da virgola)" className="input" />
                        {errors.keywords && <div className="input-error">Le parole chiave sono obbligatorie</div>}
                    </div>
                </div>

                <button type="submit" className="button green w-full mt-4">
                    Salva
                </button>
            </form>

            {id && (
                <div className="mt-8">
                    <h3>Contenuto PDF</h3>
                    <div className="mt-4 grid grid-cols-5 gap-x-4 gap-y-8">
                        {pdfs &&
                            pdfs.map((p: GQLFile, i: number) => (
                                <div key={i} className="w-48 h-48 bg-gray-100 flex flex-col items-center p-4 mr-4">
                                    <i className="las la-file-pdf la-4x" />
                                    <span>{p.filename}</span>
                                    <button type="button" className="block bg-bioetica-red px-4 py-2 text-white text-center uppercase w-full mt-4" onClick={() => PDFRM(p.id)}>
                                        Elimina
                                    </button>
                                </div>
                            ))}

                        <div className="w-48 h-48 bg-gray-100 p-4">
                            <input type="file" id="pdfs" className="hidden" onChange={evt => PDFADD(evt)} accept=".pdf" />
                            <label
                                htmlFor="pdfs"
                                className="w-full h-full border-4 border-dashed border-green-500 rounded-lg flex items-center justify-center text-6xl text-green-500
                cursor-pointer"
                            >
                                +
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
};
