import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { PagedResult } from '@biosub/common/PagedResult';
import { User } from '@biosub/common/User';
import { Book } from '@biosub/common/Book';
import { Subscription, SubscriptionInput, SubscriptionItemInput } from '@biosub/common/Subscription';

import { Layout } from '../../components/_layout';
import Select from '../../components/Select';

export const ManageSubscription: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm<SubscriptionInput>();
    const [subscriptionUser, setSubscriptionUser] = useState<User>();

    const { id } = useParams<{ id: string }>();

    const [subscriptionItems, setSubscriptionItems] = useState<SubscriptionItemInput[]>([]);

    const [users] = useQuery<{ userADList: PagedResult<User> }>({
        query: /* GraphQL */ `
            query {
                userADList(limit: 999, orderBy: lastname_ASC) {
                    edges {
                        firebaseUid
                        email
                        firstname
                        lastname
                        createdAt
                    }
                }
            }
        `
    });

    const [subscription] = useQuery<{ subscriptionADView: Subscription }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                subscriptionADView(id: $id) {
                    no
                    user {
                        firebaseUid
                    }
                    items {
                        book {
                            id
                        }
                        articles {
                            id
                        }
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        if (!users.fetching && users.data && !subscription.fetching && subscription.data && subscription.data.subscriptionADView) {
            const data = subscription.data.subscriptionADView as Subscription;
            const user = users.data.userADList.edges.find((u: User) => u.firebaseUid === data.user.firebaseUid);
            setSubscriptionUser(user);

            // wait 100ms because of the userId render
            setTimeout(() => {
                setValue('no', data.no, { shouldValidate: true, shouldDirty: true });
                setValue('userId', data.user.firebaseUid, { shouldValidate: true, shouldDirty: true });

                const si = data.items.map(i => ({ bookId: i.book.id, articleIds: i.articles.map(a => a.id) } as SubscriptionItemInput));
                setSubscriptionItems(si);
            }, 100);
        }
    }, [users, subscription, setValue]);

    const selectionChanged = (bookId: string, articleIds: string[]) => {
        const tmp = [...(subscriptionItems || [])];
        const index = tmp.findIndex(s => s.bookId === bookId);

        if (index === -1) {
            if (articleIds.length === 0) {
                return;
            }

            tmp.push({ bookId, articleIds });
        } else {
            if (articleIds.length === 0) {
                tmp.splice(index, 1);
            } else {
                tmp[index].articleIds = articleIds;
            }
        }

        setSubscriptionItems(tmp);
    };

    const [, manageSubscription] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: SubscriptionInput!) {
            manageSubscription(id: $id, input: $input) {
                id
            }
        }
    `);

    const onSubmit = async (input: SubscriptionInput) => {
        if (subscriptionItems.length === 0) {
            notification.error('Seleziona almeno un articolo di una pubblicazione');
            return;
        }

        input.items = subscriptionItems;

        const result = await manageSubscription({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/subscriptions/manage/${result.data.manageSubscription.id}`, { replace: true });
            }

            notification.success('Abbonamento salvato con successo');
        }
    };

    if (users.error || subscription.error) {
        notification.error(users.error || subscription.error || 'Si è verificato un errore sconosciuto');
        return <></>;
    }

    return (
        <Layout title="Gestisci abbonamento">
            <h2>Gestisci abbonamento</h2>

            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <div className="flex-1">
                        <h5 className="mb-1">Numero</h5>
                        <input {...register('no', { required: true })} placeholder="Numero" className="input" />
                        {errors.no && <div className="input-error">Il numero è obbligatorio</div>}
                    </div>

                    <div className="ml-8 w-1/3">
                        <h5 className="mb-1">Utente</h5>
                        {!subscriptionUser ? (
                            <>
                                <Select {...register('userId', { required: true })}>
                                    <option value="">...</option>
                                    {users.data &&
                                        users.data.userADList &&
                                        users.data.userADList.edges.map((u: User) => (
                                            <option key={u.firebaseUid} value={u.firebaseUid}>
                                                {u.firstname} {u.lastname}
                                            </option>
                                        ))}
                                </Select>
                                {errors.userId && <div className="input-error">L&apos;utente è obbligatorio</div>}
                            </>
                        ) : (
                            <div className="input">
                                <input type="hidden" {...register('userId', { required: true })} />
                                {subscriptionUser.firstname} {subscriptionUser.lastname}
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-8 p-4 bg-gray-100">
                    <h5>Pubblicazioni</h5>

                    <ArticlesForBook selectedArticleIds={subscriptionItems.map(s => s.articleIds).flat()} onSelectionChange={selectionChanged} />
                </div>

                <button type="submit" className="button green w-full mt-8">
                    Salva
                </button>
            </form>
        </Layout>
    );
};

type ArticleForBooksProps = {
    selectedArticleIds?: string[];
    onSelectionChange(bookId: string, articleIds: string[]): void;
};

const ArticlesForBook: React.FC<ArticleForBooksProps> = (props: ArticleForBooksProps) => {
    const [query] = useQuery<{ bookADList: PagedResult<Book> }>({
        query: /* GraphQL */ `
            query {
                bookADList(limit: 999) {
                    edges {
                        id
                        title
                        articles {
                            id
                            title
                        }
                    }
                }
            }
        `
    });

    const selectAllCheckboxes = (bookId: string) => {
        const allArticlesForBook = query.data?.bookADList.edges.find(b => b.id === bookId)?.articles;
        if (allArticlesForBook) {
            const allCheckboxes = document.getElementsByClassName(bookId) as HTMLCollectionOf<HTMLInputElement>;
            Array.from(allCheckboxes).forEach(c => {
                c.checked = true;
            });

            props.onSelectionChange(
                bookId,
                allArticlesForBook.map(a => a.id as string)
            );
        }
    };

    const onCheckBoxChanged = (bookId: string, articleId: string) => {
        const localArticleIds = [...(props.selectedArticleIds || [])];

        if (localArticleIds.includes(articleId)) {
            localArticleIds.splice(localArticleIds.indexOf(articleId), 1);
        } else {
            localArticleIds.push(articleId);
        }

        props.onSelectionChange(bookId, localArticleIds);
    };

    return (
        <>
            {query.data?.bookADList.edges.map(b => (
                <div key={b.id} className="mt-4">
                    <div className="bg-bioetica-red text-white font-bold text-xl p-4 flex items-center">
                        {b.title}
                        <button type="button" className="ml-4 text-xs" onClick={() => selectAllCheckboxes(b.id as string)}>
                            (Seleziona tutti)
                        </button>
                    </div>
                    <div className="py-2 px-4 bg-gray-300">
                        {b.articles && b.articles.length > 0 ? (
                            <>
                                {b.articles.map(a => (
                                    <div key={a.id} className="bg-gray-200 p-4 flex items-center">
                                        <input
                                            type="checkbox"
                                            className={`mr-4 ${b.id}`}
                                            checked={props.selectedArticleIds?.includes(a.id as string) || false}
                                            onChange={() => onCheckBoxChanged(b.id as string, a.id as string)}
                                        />
                                        {a.title}
                                    </div>
                                ))}
                            </>
                        ) : (
                            <h6 className="p-4">Nessun articolo per questa pubblicazione</h6>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};
