import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { User } from '@biosub/common/User';

import { Layout } from '../../components/_layout';

type Form = {
    email: string;
    firstname: string;
    lastname: string;
    password?: string;
};

export const ManageUser: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [query] = useQuery<{ userADView: User }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                userADView(firebaseUid: $id) {
                    email
                    firstname
                    lastname
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    useEffect(() => {
        // when creating a new user, prefill password with a random one
        if (!id) {
            const pwd = Array(20)
                .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$')
                .map(x => x[Math.floor((crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) * x.length)])
                .join('');

            setValue('password', pwd);
        }
    }, [id, setValue]);

    useEffect(() => {
        if (!query.fetching && query.data && query.data.userADView) {
            const data = query.data.userADView as User;
            setValue('email', data.email, { shouldValidate: true, shouldDirty: true });
            setValue('firstname', data.firstname, { shouldValidate: true, shouldDirty: true });
            setValue('lastname', data.lastname, { shouldValidate: true, shouldDirty: true });
        }
    }, [query, setValue]);

    const [, manageUserAsAdmin] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: UserInput!) {
            manageUserAsAdmin(firebaseUid: $id, input: $input) {
                firebaseUid
            }
        }
    `);

    const onSubmit = async (input: Form) => {
        const result = await manageUserAsAdmin({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/users/manage/${result.data.manageUserAsAdmin.firebaseUid}`, { replace: true });
            }

            notification.success('Utente salvato con successo');
        }
    };

    const [, sendWelcome] = useMutation(/* GraphQL */ `
        mutation ($name: String!, $email: String!, $password: String!) {
            sendWelcome(name: $name, email: $email, password: $password)
        }
    `);

    const sendEmail = async () => {
        const rawFormValues = getValues();
        if (!Object.values(rawFormValues).every(x => x !== '')) {
            notification.error("Tutti i campi devono essere compilati per poter inviare l'email");
        } else {
            const result = await sendWelcome({ name: `${rawFormValues.firstname} ${rawFormValues.lastname}`, email: rawFormValues.email, password: rawFormValues.password });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Email inviata con successo');
            }
        }
    };

    if (query.error) {
        notification.error(query.error);
        return <></>;
    }

    return (
        <Layout title="Gestisci Utente">
            <h2>Gestisci Utente</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="email">
                        Email *
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('email', {
                                required: "L'email è obbligatoria",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "L'indirizzo email inserito non è valido"
                                }
                            })}
                            placeholder="Email"
                            className="input"
                        />
                        {errors.email && <div className="input-error">{errors.email.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="password">
                        {!id ? (
                            <>Password *</>
                        ) : (
                            <div className="text-right">
                                Cambia password <div className="text-xs italic">(se necessario)</div>
                            </div>
                        )}
                    </label>
                    <div className="flex-1">
                        <input
                            {...register('password', {
                                required: !id && 'La password è obbligatoria',
                                pattern: {
                                    value: /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1})/,
                                    message:
                                        'La password deve essere di almeno 6 caratteri e deve contenere una cifra, una lettera maiuscola e un carattere speciale (@, &, !, ?, etc...)'
                                }
                            })}
                            type="password"
                            placeholder="Password"
                            className="input"
                        />
                        {errors.password && <div className="input-error">{errors.password.message}</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="firstname">
                        Nome *
                    </label>
                    <div className="flex-1">
                        <input {...register('firstname', { required: true })} placeholder="Nome" className="input" />
                        {errors.firstname && <div className="input-error">Il nome è obbligatorio</div>}
                    </div>
                </div>
                <div className="flex mt-4">
                    <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="lastname">
                        Cognome *
                    </label>
                    <div className="flex-1">
                        <input {...register('lastname', { required: true })} placeholder="Cognome" className="input" />
                        {errors.lastname && <div className="input-error">Il cognome è obbligatorio</div>}
                    </div>
                </div>

                <div className="flex mt-4">
                    <button type="submit" className="button green flex-1">
                        Salva
                    </button>
                    <button type="button" className="ml-4 button w-1/3" onClick={() => sendEmail()}>
                        Invia email
                    </button>
                </div>
            </form>
        </Layout>
    );
};
