import { useEffect, useState, ReactNode, ReactElement, useContext, createContext } from 'react';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

import firebase from '../firebase';

type ContextProps = {
    isAuthenticated?: boolean;
    loading?: boolean;
    login?(email: string, password: string): Promise<void>;
    logout?(): Promise<void>;
    children?: ReactNode;
};

export const AuthContext = createContext<Partial<ContextProps>>({});
export const useAuth = (): Partial<ContextProps> => useContext(AuthContext);

export const AuthProvider: React.FC<ContextProps> = ({ children }: ContextProps): ReactElement => {
    const TOKEN_KEY = 'token';

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const sessionToken = sessionStorage.getItem(TOKEN_KEY);
        if (sessionToken) {
            setIsAuthenticated(true);
        }

        setLoading(false);
    }, []);

    const login = async (email: string, password: string): Promise<void> => {
        await signInWithEmailAndPassword(firebase, email, password);

        const firebaseUser = firebase.currentUser;
        if (firebaseUser) {
            const token = await firebaseUser.getIdToken();
            const idTokenResult = await firebaseUser.getIdTokenResult();
            if (idTokenResult.claims.admin) {
                sessionStorage.setItem(TOKEN_KEY, token);
                setIsAuthenticated(true);
            } else {
                throw new Error('Non disponi dei diritti necessari per accedere');
            }
        }
    };

    const logout = async (): Promise<void> => {
        await signOut(firebase);
        sessionStorage.clear();
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                loading,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
