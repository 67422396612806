import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { Book } from '@biosub/common/Book';
import { GQLFile } from '@biosub/common/GQLFile';

import { Layout } from '../../components/_layout';
import WYSIWYGEditor from '../../components/Wysiwyg';

type Form = {
    title: string;
    description: string;
};

export const ManageBook: React.FC = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        register,
        setValue,
        control,
        formState: { errors }
    } = useForm<Form>();

    const { id } = useParams<{ id: string }>();

    const [cover, setCover] = useState<GQLFile>();

    const [book] = useQuery<{ bookADView: Book }>({
        query: /* GraphQL */ `
            query ($id: ID!) {
                bookADView(id: $id) {
                    title
                    description
                    cover {
                        url
                    }
                }
            }
        `,
        variables: { id },
        pause: !id
    });

    if (book.error) {
        notification.error(book.error);
    }

    useEffect(() => {
        if (!book.fetching && book.data && book.data.bookADView) {
            const data = book.data.bookADView as Book;
            setValue('title', data.title, { shouldValidate: true, shouldDirty: true });
            setValue('description', data.description, { shouldValidate: true, shouldDirty: true });

            if (data.cover) {
                setCover(data.cover);
            }
        }
    }, [book, setValue]);

    const [, manageBook] = useMutation(/* GraphQL */ `
        mutation ($id: ID, $input: BookInput!) {
            manageBook(id: $id, input: $input) {
                id
            }
        }
    `);

    const onSubmit = async (input: Form) => {
        const result = await manageBook({ id, input });

        if (result.error) {
            notification.error(result.error);
        } else {
            if (!id) {
                navigate(`/books/manage/${result.data.manageBook.id}`, { replace: true });
            }

            notification.success('Libro salvato con successo!');
        }
    };

    const [, addCover] = useMutation(/* GraphQL */ `
        mutation ($id: ID!, $file: Upload!) {
            addCover(id: $id, file: $file) {
                id
                url
            }
        }
    `);

    const coverADD = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];

            const result = await addCover({ id, file });

            if (result.error) {
                notification.error(result.error);
            } else {
                setCover(result.data.addCover);

                notification.success('Cover aggiunta con successo!');
            }
        }
    };

    const [, removeCover] = useMutation(/* GraphQL */ `
        mutation ($id: ID!) {
            removeCover(id: $id)
        }
    `);

    const coverRM = async () => {
        if (window.confirm('Sei sicuro di voler eliminare la cover?')) {
            const result = await removeCover({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                setCover(undefined);

                notification.success('Cover rimossa con successo!');
            }
        }
    };

    return (
        <Layout title="Gestisci pubblicazione">
            <h2>Gestisci pubblicazione</h2>
            <span className="italic font-bold text-xs">* Campi obbligatori</span>

            <div className="mt-4 flex">
                <form className="flex-1" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex">
                        <label className="font-bold w-2/12 pr-4 flex justify-end items-center" htmlFor="title">
                            Titolo *
                        </label>
                        <div className="flex-1">
                            <input {...register('title', { required: true })} placeholder="Titolo" className="input" />
                            {errors.title && <div className="input-error">Il titolo è obbligatorio</div>}
                        </div>
                    </div>
                    <div className="flex mt-4">
                        <label className="font-bold w-2/12 pr-4 flex justify-end mt-1" htmlFor="description">
                            Descrizione *
                        </label>
                        <div className="flex-1 bg-gray-200 rounded">
                            <Controller
                                render={({ field }) => <WYSIWYGEditor value={field.value} onChange={field.onChange} />}
                                name="description"
                                control={control}
                                rules={{ required: true }}
                            />
                            {errors.description && <div className="input-error">La descrizione è obbligatoria</div>}
                        </div>
                    </div>

                    <button type="submit" className="button green w-full mt-4">
                        Salva
                    </button>
                </form>

                {id && (
                    <div className="ml-8 bg-gray-200 rounded p-4">
                        <h3>Cover</h3>
                        <div className="flex mt-2">
                            {cover ? (
                                <div className="w-64">
                                    <img src={cover.url} alt="Immagine libro" title="Immagine libro" />
                                    <button type="button" className="block bg-bioetica-red px-4 py-2 text-white text-center uppercase w-full mt-4" onClick={() => coverRM()}>
                                        Elimina
                                    </button>
                                </div>
                            ) : (
                                <div className="w-64 h-64">
                                    <input type="file" id="cover" className="hidden" onChange={evt => coverADD(evt)} accept=".jpg,.png,.jpeg" />
                                    <label
                                        htmlFor="cover"
                                        className="w-full h-full border-4 border-dashed border-green-500 rounded-lg flex items-center justify-center text-6xl text-green-500 cursor-pointer"
                                    >
                                        +
                                    </label>
                                </div>
                            )}
                        </div>

                        <hr className="my-4 border-bioetica-red" />

                        <h3>Articoli</h3>
                        <Link to={`/articles/${id}`} className="mt-2 button green">
                            Gestisci articoli
                        </Link>
                    </div>
                )}
            </div>
        </Layout>
    );
};
