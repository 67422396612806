import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import dayjs from 'dayjs';

import notification from '../../notification';

import { PagedResult } from '@biosub/common/PagedResult';
import { User } from '@biosub/common/User';

import { Layout } from '../../components/_layout';
import Select from '../../components/Select';

export const UserList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('lastname_ASC');

    const [query] = useQuery<{ userADList: PagedResult<User> }>({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: UserOrderByInput) {
                userADList(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        firebaseUid
                        email
                        firstname
                        lastname
                        createdAt
                        logincount
                        lastlogin
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteUserAsAdmin] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteUserAsAdmin(firebaseUid: $id) {
                firebaseUid
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo utente?')) {
            const result = await deleteUserAsAdmin({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Utente eliminato con successo');
            }
        }
    };

    return (
        <Layout title="Elenco utenti">
            <div className="flex">
                <div className="flex-1 flex items-center">
                    <Link to="/users/manage" className="mr-4 text-green-500">
                        <i className="las la-plus-circle la-3x" />
                    </Link>

                    <h3>Utenti</h3>
                </div>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina utenti per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="lastname_ASC">Cognome A-Z</option>
                        <option value="lastname_DESC">Cognome Z-A</option>
                        <option value="email_ASC">Email A-Z</option>
                        <option value="email_DESC">Email Z-A</option>
                        <option value="createdAt_ASC">Data creazione crescente</option>
                        <option value="createdAt_DESC">Data creazione decrescente</option>
                        <option value="lastlogin_ASC">Data ultimo login crescente</option>
                        <option value="lastlogin_DESC">Data ultimo login decrescente</option>
                        <option value="logincount_ASC">Conta login crescente</option>
                        <option value="logincount_DESC">Conta login decrescente</option>
                    </Select>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-bioetica-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 font-semibold">#</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Nome completo</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Email</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Registrato il</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Ultimo accesso</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Tot. accessi</th>
                                <th className="w-3/12 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.userADList.edges.map((u: User, i: number) => (
                                <tr key={u.firebaseUid} className="odd:bg-gray-200">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">
                                        {u.lastname} {u.firstname}
                                    </td>
                                    <td className="p-4">{u.email}</td>
                                    <td className="p-4">{dayjs(u.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                                    <td className="p-4">{u.lastlogin ? dayjs(u.lastlogin).format('DD/MM/YYYY HH:mm') : 'Mai'}</td>
                                    <td className="p-4">{u.logincount || '0'}</td>
                                    <td className="p-4 border-l border-gray-200 flex">
                                        <Link to={`/users/manage/${u.firebaseUid}`} className="flex-1 button small green mr-2">
                                            Modifica
                                        </Link>
                                        <button className="flex-1 button small red" onClick={() => doDelete(u.firebaseUid)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.userADList.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data?.userADList.pageInfo.endCursor || 0)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
