import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { PagedResult } from '@biosub/common/PagedResult';
import { Subscription } from '@biosub/common/Subscription';

import { Layout } from '../../components/_layout';
import Select from '../../components/Select';

export const SubscriptionList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('no_ASC');

    const [query] = useQuery<{ subscriptionADList: PagedResult<Subscription> }>({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: SubscriptionOrderByInput) {
                subscriptionADList(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        no
                        user {
                            firstname
                            lastname
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteSubscription] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteSubscription(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questo abbonamento?')) {
            const result = await deleteSubscription({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Abbonamento eliminato con successo');
            }
        }
    };

    return (
        <Layout title="Elenco abbonamenti">
            <div className="flex">
                <div className="flex-1 flex items-center">
                    <Link to="/subscriptions/manage" className="mr-4 text-green-500">
                        <i className="las la-plus-circle la-3x" />
                    </Link>

                    <h3>Abbonamenti</h3>

                    <div className="w-64 ml-auto">
                        <div className="text-right text-sm mb-1">Ordina abbonamenti per</div>
                        <Select onChange={val => fetch(cursor, val.target.value)}>
                            <option value="no_ASC">Numero A-Z</option>
                            <option value="no_DESC">Numero Z-A</option>
                        </Select>
                    </div>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-bioetica-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 font-semibold">#</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Numero</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Nome completo</th>
                                <th className="w-3/12 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.subscriptionADList.edges.map((s: Subscription, i: number) => (
                                <tr key={i} className="odd:bg-gray-200">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">{s.no}</td>
                                    <td className="p-4">
                                        {s.user.firstname} {s.user.lastname}
                                    </td>
                                    <td className="p-4 border-l border-gray-200 flex">
                                        <Link to={`/subscriptions/manage/${s.id}`} className="flex-1 button small green mr-2">
                                            Modifica
                                        </Link>
                                        <button className="flex-1 button small red" onClick={() => doDelete(s.id as string)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.subscriptionADList.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data?.subscriptionADList.pageInfo.endCursor || 0)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
