import { forwardRef } from 'react';

type Props = React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
// eslint-disable-next-line react/display-name
export default forwardRef<HTMLSelectElement, Props>((props, ref) => (
    <div className="inline-block relative w-full">
        <select ref={ref} {...props} className="block appearance-none w-full bg-gray-200 px-6 py-4 pr-12 rounded text-xl" />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5 text-gray-700">
            <svg className="fill-current h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
        </div>
    </div>
));
