import {  useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FirebaseError } from '@firebase/app';

import { useAuth } from '../contexts/auth';

import notification from '../notification';

type Form = {
    email: string;
    password: string;
};

export const Login: React.FC = () => {
    const { login } = useAuth();
     const navigate =  useNavigate();
    const {
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<Form>();

    const queryParams = new URLSearchParams(window.location.search);

    const onSubmit = async (values: Form) => {
        try {
            if (login) {
                await login(values.email, values.password);
               navigate(queryParams.get('returnUrl') || '/');
            }
        } catch (e) {
            if (e instanceof FirebaseError || e instanceof Error) {
                notification.error(e);
            }
        }
    };

    return (
        <div className="bg-gray-200 h-full flex items-center justify-center">
            <div className="bg-white shadow rounded p-6 w-1/5">
                <h3>Accedi</h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <label htmlFor="email" className="font-bold">
                        Email
                    </label>
                    <input
                        {...register('email', {
                            required: "L'email è obbligatoria",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "L'indirizzo email inserito non è valido"
                            }
                        })}
                        placeholder="Email"
                        type="email"
                        className="input"
                    />
                    {errors.email && <div className="input-error">{errors.email.message}</div>}

                    <div className="mt-4">
                        <label htmlFor="password" className="font-bold">
                            Password
                        </label>
                        <input {...register('password', { required: true })} placeholder="Password" type="password" className="input" />
                        {errors.password && <div className="input-error">La password è obbligatoria</div>}
                    </div>

                    <button type="submit" className="mt-4 button green w-full">
                        LOGIN
                    </button>
                </form>
            </div>
        </div>
    );
};
