import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import notification from '../../notification';

import { PagedResult } from '@biosub/common/PagedResult';
import { Book } from '@biosub/common/Book';

import { Layout } from '../../components/_layout';
import Select from '../../components/Select';

export const BookList: React.FC = () => {
    const limit = 20;
    const [cursor, setCursor] = useState(0);
    const [orderBy, setOrderBy] = useState('title_ASC');

    const [query] = useQuery<{ bookADList: PagedResult<Book> }>({
        query: /* GraphQL */ `
            query($limit: Int!, $cursor: Int, $orderBy: BookOrderByInput) {
                bookADList(limit: $limit, cursor: $cursor, orderBy: $orderBy) {
                    edges {
                        id
                        title
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        variables: { limit, cursor, orderBy }
    });

    if (query.error) {
        notification.error(query.error);
    }

    const fetch = (cur: number, order?: string) => {
        setCursor(+(cur !== 0) && (cur || cursor));
        if (order) {
            setOrderBy(order);
        }

        window.scrollTo(0, 0);
    };

    const [, deleteBook] = useMutation(/* GraphQL */ `
        mutation($id: ID!) {
            deleteBook(id: $id) {
                id
            }
        }
    `);

    const doDelete = async (id: string) => {
        if (window.confirm('Sei sicuro di voler eliminare questa pubblicazione?')) {
            const result = await deleteBook({ id });

            if (result.error) {
                notification.error(result.error);
            } else {
                notification.success('Pubblicazione eliminata con successo');
            }
        }
    };

    return (
        <Layout title="Elenco pubblicazioni">
            <div className="flex">
                <div className="flex-1 flex items-center">
                    <Link to="/books/manage" className="mr-4 text-green-500">
                        <i className="las la-plus-circle la-3x" />
                    </Link>

                    <h3>Pubblicazioni</h3>
                </div>

                <div className="w-64 ml-auto">
                    <div className="text-right text-sm mb-1">Ordina libri per</div>
                    <Select onChange={val => fetch(cursor, val.target.value)}>
                        <option value="title_ASC">Titolo A-Z</option>
                        <option value="title_DESC">Titolo Z-A</option>
                    </Select>
                </div>
            </div>

            {query.fetching && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl">Caricamento...</span>
                </div>
            )}

            {query.error && (
                <div className="p-4 flex justify-center">
                    <span className="text-xl text-bioetica-red">Si è verificato un errore nel gestire la richiesta</span>
                </div>
            )}
            {query.data && (
                <>
                    <table className="mt-4 table-auto w-full">
                        <thead>
                            <tr>
                                <th className="w-12 text-xl font-semibold">#</th>
                                <th className="px-4 py-2 text-xl font-semibold text-left">Titolo</th>
                                <th className="w-3/12 px-4 py-2 text-xl font-semibold">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {query.data.bookADList.edges.map((b: Book, i: number) => (
                                <tr key={b.id} className="odd:bg-gray-100">
                                    <td className="p-2 text-center">{cursor + i + 1}</td>
                                    <td className="p-4">{b.title}</td>
                                    <td className="p-4 border-l border-gray-200 flex">
                                        <Link to={`/books/manage/${b.id}`} className="flex-1 button small green">
                                            Modifica
                                        </Link>
                                        &nbsp;
                                        <button className="flex-1 button small red" onClick={() => doDelete(b.id as string)}>
                                            Elimina
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="mt-8 flex justify-center">
                        <div className="border border-gray-700 rounded flex">
                            {cursor > 0 && (
                                <button className="block w-32 p-4 text-center font-semibold" onClick={() => fetch(cursor - limit)}>
                                    INDIETRO
                                </button>
                            )}

                            {query.data.bookADList.pageInfo.hasNextPage && (
                                <button
                                    className="even:border-l border-gray-700 block w-32 p-4 text-center font-semibold"
                                    onClick={() => fetch(query.data?.bookADList.pageInfo.endCursor || 0)}
                                >
                                    AVANTI
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
