import { useCallback, useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import List from '@editorjs/list';
import Embed from '@editorjs/embed';
import Underline from '@editorjs/underline';

interface Props {
    onChange: (data: string | undefined) => void;
    value?: string;
}

const WYSIWYG: React.FC<Props> = ({ onChange, value }: Props) => {
    const ejInstance = useRef<EditorJS>();

    const initEditor = useCallback(() => {
        const editor = new EditorJS({
            holder: 'editorjs',
            placeholder: 'Inizia a scrivere...',
            onReady: () => {
                ejInstance.current = editor;
            },
            onChange: () => {
                editor.saver.save().then(content => onChange(JSON.stringify(content)));
            },
            tools: {
                header: Header,
                quote: Quote,
                underline: Underline,
                list: {
                    class: List,
                    inlineToolbar: true
                },
                embed: {
                    class: Embed,
                    config: {
                        services: {
                            youtube: true
                        }
                    }
                }
            }
        });
    }, [onChange]);

    // This will run only once
    useEffect(() => {
        if (!ejInstance.current) {
            initEditor();
        }

        return () => {
            ejInstance.current?.destroy();
            ejInstance.current = undefined;
        };
    }, [initEditor]);

    useEffect(() => {
        setTimeout(() => {
            if (value) {
                const parsed = JSON.parse(value);
                if (parsed.blocks.length > 0) {
                    ejInstance.current?.render(parsed);
                }
            }
        }, 800);
    }, [value]);

    return <div id="editorjs" />;
};

export default WYSIWYG;
